import { escapeHtml } from "../../../utils";

const OPEN_WIDTH = '51px';
const CLOSE_WIDTH = '0px';

class SubMenu extends HTMLElement {
    constructor() {
        super();
    }

    set display(value) {
        this.setAttribute('display', value);
    }

    get display() {
        return this.getAttribute('display') === 'true';
    }

    set items(value) {
        this.setAttribute('items', JSON.stringify(value));
    }

    get items() {
        return this.getAttribute('items');
    }

    set selection(value) {
        this.setAttribute('selection', value);
    }

    get selection() {
        return this.getAttribute('selection');
    }

    static get observedAttributes() {
        return ['display', 'items', 'selection'];
    }

    connectedCallback() {
        this.render();
        this.navElem = this.shadowRoot.querySelector('nav');
        this.attachEventHandlers();
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (!this.shadowRoot) { return; }
        if (name === 'display') {
            this.navElem.parentElement.style.height = newValue === 'true' ? OPEN_WIDTH : CLOSE_WIDTH;
        } else if (name === 'items') {
            const itemsData = JSON.parse(newValue);
            if (!Array.isArray(itemsData)) {
                this.navElem.innerHTML = '';
                return;
            }
            this.navElem.innerHTML = this.buildItems(itemsData);
        } else if (name === 'selection') {
            this.updateSelection(newValue);
        }
    }

    updateSelection = (path, elem) => {
        const itemsElems = [].slice.call(this.shadowRoot.querySelectorAll('a'));
        itemsElems.forEach(item => item.classList.remove('selected'));
        elem = elem || itemsElems.find(item => item.dataset.path === path);
        elem.classList.add('selected');
    }

    buildItems = itemsData => {
        let childs = ``;
        const appPath = `${location.origin}/app/${history.state.appId}`;
        itemsData.forEach(data => {
            if (data) {
                const path = escapeHtml(data.path);
                childs += `<a href=${appPath}${path} data-path=${path}>${data.name}</a>`;
            }
        });
        return childs;
    }

    render() {
        const container = document.createElement('div');
        container.setAttribute('class', 'sub-menu-con');
        container.innerHTML = `
        ${getStyle()}
        <nav>
        </nav>`;
        const shadowRoot = this.attachShadow({ mode: 'open' });
        shadowRoot.appendChild(container);
    }

    attachEventHandlers = () => {
        this.navElem.addEventListener('click', this.onItemClick);
    }

    disconnectedCallback() {
        this.navElem.removeEventListener('click', this.onItemClick);
    }

    onItemClick = event => {
        const name = event.target.dataset.path;
        if (event.ctrlKey || event.metaKey) { return; }
        event.preventDefault();
        this.updateSelection(name, event.target);
        this.dispatchEvent(new CustomEvent('subMenuNavClick', { detail: { name } }));
    }
}

function getStyle() {
    return `<style>
    .sub-menu-con {
        height: 0px;
        overflow: hidden;
        transition: height 0.2s;
    }
    a {
        white-space: normal;
        float: left;
        width: auto;
        border: none;
        display: block;
        outline: 0;
        overflow: hidden;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        font-weight: normal;
        margin: 15px 23px;
        padding: 0px 2px;
        font-size: 15px;
        color: var(--default-text-color);
        transition: border-color 0.2s ease; 
        border-bottom: 2px solid white;
        pointer-events: auto;
    }
    a:hover {
        border-bottom: 2px solid var(--primary-color-hover);
    }
    .selected {
        border-bottom: 2px solid var(--primary-color) !important;
    }
    nav {
        height: 50px;
        background: white;
        border-bottom: solid 1px #E5E5E5;
        pointer-events: none;
    }
    </style>`;
}

customElements.define('sub-menu', SubMenu);