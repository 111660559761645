import './styles/logout.scss';
import { showParticles } from '../../services/particlesService';
import k2Logo from '../../../../static/loginLogo.svg';
import reconnectIcon from '../../../../static/reconnectIcon.png';
import { i18n } from '../../services/I18nManager';

class LogoutContainer extends HTMLElement {

    connectedCallback() {
        document.title = `${i18n.translate('browser.titlePrefix')}Logout`;
        this.render();
        this.reconnectBtn = this.querySelector('.k2-primary-reversed-btn');
        this.attachEventHandlers();
        showParticles('logoutContainer');
    }

    render() {
        this.innerHTML = `
        <div id="logoutContainer" class="logout-container">
            <img class="logo" src=${k2Logo} alt="K2VIEW Logo">
            <img class="reconnect-icon" src=${reconnectIcon} alt="reconnect icon">
            <span>${i18n.translate('logout.message')}</span>
            <hr>
            <button class="k2-primary-reversed-btn">${i18n.translate('logout.btn')}</button>
        </div>`;
    }

    attachEventHandlers = () => {
        this.reconnectBtn.addEventListener('click', this.onReconnectClick);
    }

    disconnectedCallback() {
        this.reconnectBtn.removeEventListener('click', this.onReconnectClick);
    }

    onReconnectClick = () => {
        location.href = location.origin;
    }
}
customElements.define('logout-container', LogoutContainer);