import * as network from './network';
import { invokeWS } from './fabric';
import '../webComponents/containers/logout';
import { getCookie } from '../../utils';
import { COOKIES_KEYS } from '../../globals';

export const LOGIN_SUCCESS = 'OK';

export async function authenticate(params) {
    const res = invokeWS('authenticate', params, network.POST_REQUEST);
    return res;
}

export async function logout() {
    try {
        if (getCookie(COOKIES_KEYS.USE_LOGIN_PAGE) !== 'true') { //display logout page on SAML 
            await invokeWS('logout', {}, network.POST_REQUEST);
            displayLogoutPage();
            return;
        }
        await invokeWS('logout', {}, network.POST_REQUEST);
        location.href = location.origin;
    } catch (error) {
        console.error(error);
    }
}

function displayLogoutPage() {
    const logout = document.createElement('LOGOUT-CONTAINER');
    window.history.replaceState({}, 'logout', `${location.origin}/app/logout`);
    document.querySelector('app-wireframe').remove();
    document.querySelector('.app-root').appendChild(logout);
}