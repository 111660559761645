import './app-header';
import './left-drawer';
import '../display/sub-menu';
import './styles/app-wireframe.scss';
import { router } from '../../router/Router';
import { getAppAPI, expand, getWidgetsApi } from '../../services/appAPI';
import { setInterval } from 'core-js';
import { FRAMEWORK_ID } from '../../../globals';
import { cleanPath, isIframeOnSameOrigin, setPluginDefaultFont } from '../../../utils';
import { disableKeyBoardSave, addDAPScript, fetchApps } from '../../services/generalServices';
import { i18n } from '../../services/I18nManager';

const CHECK_URL_DELAY = 30;

class Wireframe extends HTMLElement {

    constructor() {
        super();
        this.checkAppPath = this.checkAppPath.bind(this);
    }

    set menu(value) {
        this.setAttribute('menu', JSON.stringify(value));
    }

    get menu() {
        return this.getAttribute('menu');
    }

    set route(value) {
        this.setAttribute('route', value);
    }

    static get observedAttributes() {
        return ['menu', 'route', 'appselection'];
    }

    set appselection(value) {
        this.setAttribute('appselection', value);
    }

    get appselection() {
        return this.getAttribute('appselection');
    }

    getSelectedMenuItem = () => this.menuDrawer ? this.menuDrawer.selection : null;

    connectedCallback() {
        this.render();
        this.appHeader = this.querySelector('app-header');
        this.menuDrawer = this.querySelector('left-drawer');
        this.mainContainer = this.querySelector('.sub-container');
        this.iframeElem = this.querySelector('.micro-app-container');
        this.subMenu = this.querySelector('sub-menu');
        this.attachEventHandlers();
        this.checkAppPath();
    }

    checkAppPath() {
        setInterval(() => {
            try {
                if (!this.iframeElem || !this.iframeElem.parentElement || !this.iframeElem.contentWindow || !this.iframeElem.contentWindow.location || !isIframeOnSameOrigin(this.iframeElem)) { return; }
                const childLocation = this.iframeElem.contentWindow.location;
                const appId = history.state && history.state.appId ? history.state.appId : this.iframeElem.contentWindow.k2api.applicationId;
                let childURL = childLocation.href.replace(`${childLocation.origin}/`, '').replace(`static/${appId}`, '').replace('static/', '').replace('about:blank', '');
                childURL = childURL.startsWith('/index.html') ? childURL.replace('index.html', '') : childURL;
                childURL = cleanPath(childURL);
                if ((!childURL || childURL === '/') && history.state && history.state.appPath !== '/') {
                    window.history.replaceState({ ...history.state, appId, appPath: '/' }, '', appId ? `/app/${appId}/${childLocation.search}` : '/');
                } else if (!location.href.endsWith(childURL + childLocation.search) && appId) {
                    childURL = childURL.startsWith('/') ? childURL : `/${childURL}`;
                    window.history.replaceState({ ...history.state, appPath: childURL }, appId, `/app/${appId}${childURL}${childLocation.search}`);
                }
                if (history.state && history.state.appPath && this.appHeader.selection !== history.state.appPath) {
                    this.appHeader.selection = history.state.appPath;
                }
            } catch (error) {
                console.log(error);
            }
        }, CHECK_URL_DELAY);
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (!this.innerHTML) { return; }
        if (name === 'menu') {
            this.menuDrawer.menu = newValue;
        } else if (name === 'appselection') {
            this.menuDrawer.selection = newValue;
            this.appHeader.appname = this.menuDrawer.selectionDisplayName;
        }
    }

    setAppRoute = (appPath, innerPath = '', applicationId) => {
        if (!appPath) {
            this.iframeElem.src = 'about:blank';
            return;
        }
        const appConfigs = this.getAppConfigs(applicationId);
        this.setAppExpand(appConfigs);
        if (this.iframeElem.src === appPath && this.iframeElem.contentWindow.k2api.navigateTo) {
            this.iframeElem.contentWindow.k2api.navigateTo(innerPath);
            const state = window.history.state;
            if (state.appPath !== innerPath) {
                window.history.replaceState({ ...state, appPath: innerPath }, state.appId, `/app/${state.appId}/${innerPath}`);
            }
            return;
        }

        let url = this.getIframeUrl(appPath, innerPath, applicationId);
        const newIframeElem = document.createElement('iframe');
        newIframeElem.classList.add('micro-app-container');
        newIframeElem.src = url;
        newIframeElem.style.visibility = 'hidden';
        newIframeElem.onload = () => {
            try {
                if (isIframeOnSameOrigin(newIframeElem) && newIframeElem.contentWindow.applicationId === FRAMEWORK_ID) {
                    newIframeElem.src = 'about:blank';
                    window.history.replaceState({ appId: '', menuItemId: null, appPath: '/' }, '', '/');
                    innerPath = '';
                    return;
                }
                newIframeElem.style.visibility = 'visible';
                this.onIframeLoaded(newIframeElem, innerPath, appConfigs, applicationId);
                innerPath = '';
            } catch (error) {
                console.error(error);
            }
        };

        this.mainContainer.replaceChild(newIframeElem, this.iframeElem);
        newIframeElem.contentWindow.k2api = getAppAPI(this.appHeader, applicationId); // create name space before iframe is loaded
        newIframeElem.contentWindow.k2widgets = getWidgetsApi();
        this.iframeElem = newIframeElem;
    }

    getAppConfigs = appId => JSON.parse(this.menu).find(data => data.appId === appId);

    getIframeUrl = (appPath, innerPath = '', applicationId) => {
        let url;
        const appData = this.getAppConfigs(applicationId);
        if (appData && appData.origin) {
            url = appData.origin;
        } else {
            url = appPath.includes(window.location.origin) ? appPath : `${window.location.origin}/${appPath}`;
            url = innerPath.endsWith('.html') || innerPath.includes('.html#') ? `${url}${innerPath}` : `${url}/`;
        }
        return url;
    }

    onIframeLoaded = (newIframeElem, innerPath, appConfigs, applicationId) => {
        if (!isIframeOnSameOrigin(newIframeElem)) { return; }
        if (!appConfigs.disableFontInjection) { // don't inject fonts to app - custom config
            setPluginDefaultFont(window.frameworkFontFace, newIframeElem);
        }
        disableKeyBoardSave(newIframeElem.contentWindow);
        if (!newIframeElem.contentWindow.k2api) {
            newIframeElem.contentWindow.k2api = getAppAPI(this.appHeader, applicationId); //we need it for page change in multipage app
            newIframeElem.contentWindow.k2widgets = getWidgetsApi();
        }
        if (innerPath) {
            this.appHeader.selection = innerPath;
            const state = window.history.state;
            if (newIframeElem.contentWindow.k2api.navigateTo) { //single page app routing
                if (newIframeElem.contentWindow.k2api.navigateTo(innerPath) && !window.location.href.endsWith(innerPath)) {
                    let { appId, menuItemId } = state;
                    appId = appId || applicationId;
                    window.history.replaceState({ appId, menuItemId, appPath: innerPath }, appId, `/app/${appId}${innerPath}`);
                    newIframeElem.contentWindow.k2api.applicationId = appId;
                    addDAPScript(newIframeElem.contentDocument.head);
                    return;
                }
            } else { // multipage website
                let { appId, menuItemId } = state;
                appId = appId || applicationId;
                newIframeElem.contentWindow.k2api.applicationId = appId;
                const newPath = `/app/${appId}${innerPath}`;
                if (!newIframeElem.contentWindow.k2api.navigateTo) {
                    window.history.replaceState({ appId, menuItemId }, appId, newPath);
                }
            }
        } else if (newIframeElem.contentWindow.k2api.navigateTo) {
            newIframeElem.contentWindow.k2api.navigateTo('/');
        }
        addDAPScript(newIframeElem.contentDocument.head);
    }

    setAppExpand = (appData) => {
        try {
            expand(appData.expand);
        } catch (error) {
            console.log('setAppExpand', error);
        }
    }

    render() {
        this.innerHTML = `
        <div class="wireframe-container">
            <app-header></app-header>
            <div class="main-container">
                <left-drawer open="false"></left-drawer>
                <div class="sub-container">
                    <sub-menu></sub-menu>
                    <iframe class="micro-app-container"></iframe>
                </div>
            </div>
        </div>`;
    }

    attachEventHandlers = () => {
        this.appHeader.addEventListener('menuBtnStateChange', this.onMenuBtnStateChange);
        this.appHeader.addEventListener('headerNavClick', this.onHeaderNavClick);
        this.menuDrawer.addEventListener('menuItemSelectionChange', this.onMenuItemSelectionChange);
        this.subMenu.addEventListener('subMenuNavClick', this.onSubMenuNavClick);
        this.appHeader.addEventListener('updateSubMenuSelection', this.onUpdateSubMenuSelection);
    }

    disconnectedCallback() {
        this.appHeader.removeEventListener('menuBtnStateChange', this.onMenuBtnStateChange);
        this.appHeader.removeEventListener('headerNavClick', this.onHeaderNavClick);
        this.menuDrawer.removeEventListener('menuItemSelectionChange', this.onMenuItemSelectionChange);
        this.subMenu.removeEventListener('subMenuNavClick', this.onSubMenuNavClick);
        this.appHeader.removeEventListener('updateSubMenuSelection', this.onUpdateSubMenuSelection);
    }

    onMenuItemSelectionChange = event => {
        const selectionData = event.detail;
        let url = '';
        if (selectionData) {
            const path = router.getPathByAppId(selectionData.appId);
            url = `${window.location.origin}/${path}`;
            router.onNavigate(selectionData.appId, selectionData.itemId);
            this.appHeader.appname = selectionData.name;
        }
        this.setAppRoute(url, '', selectionData.appId);
        this.resetHeaderNavigation();
        delete this.appHeader.dataset.peak;
        setTimeout(() => {
            this.menuDrawer.open = false;
            this.appHeader.menuBtn.open = false;
        }, 200);
    }

    resetHeaderNavigation = () => {
        this.subMenu.display = false;
        this.appHeader.selection = '';
        this.appHeader.navigation = [];
    }

    onMenuBtnStateChange = async event => {
        try {
            this.menuDrawer.open = event.detail.isOpen;
            if (!event.detail.isOpen) {
                if (this.appHeader.dataset.peak) {
                    delete this.appHeader.dataset.peak;
                    window.k2api.expand(true);
                }
                return;
            }
            const currApps = router.getAppsData();
            const updatedApps = await fetchApps();
            if (currApps.length !== updatedApps.length || 
                updatedApps.find((ua, index) => ua.appId !== currApps[index].appId)) {    
                router.setAppsData(updatedApps);
                this.menu = updatedApps;
                const addedApps = updatedApps.filter(u => !currApps.find(ca => ca.appId === u.appId));
                if (!addedApps.length) return;
                i18n.initAppsTranslations(addedApps); // load translation for added apps
            } 
        } catch (error) {
            console.error(error);
        }
    }

    onHeaderNavClick = event => {
        let selection;
        if (event.detail.children) {
            this.subMenu.items = event.detail.children;
            this.subMenu.display = true;
            selection = event.detail.children[0].path;
            this.subMenu.selection = selection;
        } else {
            this.subMenu.display = false;
            selection = event.detail.name;
        }
        if (this.iframeElem.contentWindow.k2api.navigateTo) {
            this.iframeElem.contentWindow.k2api.navigateTo(selection);
        } else {
            const appId = history.state.appId;
            this.iframeElem.contentWindow.location.pathname = `/static/${appId}${selection}`;
        }
    }

    onSubMenuNavClick = event => {
        if (this.iframeElem.contentWindow.k2api.navigateTo) {
            this.iframeElem.contentWindow.k2api.navigateTo(event.detail.name);
        } else {
            const appId = history.state.appId;
            this.iframeElem.contentWindow.location.pathname = `/static/${appId}${event.detail.name}`;
        }
    }

    onUpdateSubMenuSelection = event => {
        if (event.detail.items) {
            this.subMenu.items = event.detail.items;
            this.subMenu.display = true;
            this.subMenu.selection = event.detail.selection;
        } else {
            this.subMenu.display = false;
        }
    }
}
customElements.define('app-wireframe', Wireframe);