class WidgetWrapper extends HTMLElement {

    constructor() {
        super();
        this.onUnmount = () => { };
    }

    get wsrc() {
        return this.getAttribute('wsrc');
    }

    set wsrc(value) {
        this.setAttribute('wsrc', value);
    }

    get useiframe() {
        return this.getAttribute('useiframe') === 'true';
    }

    set useiframe(value) {
        this.setAttribute('useiframe', value);
    }

    static get observedAttributes() {
        return ['wsrc'];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (!this.rootElem) { return; }

        if (name === 'wsrc' && newValue) {
            const wrapperCon = this.rootElem.querySelector('#widgetWrapperContainer');
            const script = document.createElement('script');
            script.onload = () => {
                this.dispatchEvent(new CustomEvent('widgetLoaded', { detail: wrapperCon }));
            };
            script.src = newValue;
            this.rootElem.appendChild(script);
        }
    }

    connectedCallback() {
        if (this.rootElem) { return; }

        const container = document.createElement('div');
        container.id = 'widgetWrapperContainer';

        if (this.getAttribute('useiframe') === 'true') {
            this.innerHTML = container.outerHTML;
            this.rootElem = this;
        } else {
            const shadowRoot = this.attachShadow({ mode: 'open' });
            shadowRoot.appendChild(container);
            this.rootElem = this.shadowRoot;
        }
    }

    disconnectedCallback() {
        this.dispatchEvent(new CustomEvent('widgetUnmount'));
    }

}
customElements.define('widget-wrapper', WidgetWrapper);