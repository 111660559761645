import k2Logo from '../../../../static/Logo.svg';
import userIcon from '../../../../static/userIcon.png';
import '../display/menu-btn';
import '../containers/user-details';
import './styles/app-header.scss';
import { cleanPath, escapeHtml } from '../../../utils';
import { changePasswordService } from '../../services/ChangePasswordService';
import { i18n } from '../../services/I18nManager';

const OPEN_WIDTH = '50px';
const CLOSE_WIDTH = '0px';

class AppHeader extends HTMLElement {
    constructor() {
        super();
    }

    set display(value) {
        this.setAttribute('display', value);
    }

    get display() {
        return this.getAttribute('display') === 'true';
    }

    set selection(value) {
        if (this.getAttribute('navigation') && this.getAttribute('navigation') !== '[]') {
            this.setAttribute('selection', cleanPath(value));
        }
    }

    get selection() {
        return this.getAttribute('selection');
    }

    set navigation(value) {
        this.setAttribute('navigation', JSON.stringify(value));
    }

    get navigation() {
        return this.getAttribute('navigation');
    }

    set appname(value) {
        this.setAttribute('appname', value);
    }

    static get observedAttributes() {
        return ['display', 'navigation', 'selection', 'appname'];
    }

    connectedCallback() {
        this.render();
        this.menuBtn = this.querySelector('menu-btn');
        this.navElem = this.querySelector('nav');
        this.userBtn = this.querySelector('.user-icon');
        this.appName = this.querySelector('.app-name');
        this.logo = this.querySelector('.app-header-logo');
        this.userDetails = document.createElement('user-details');
        document.body.appendChild(this.userDetails);
        this.attachEventHandlers();
        changePasswordService.initDialog();
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (!this.innerHTML || oldValue === newValue) { return; }
        if (name === 'display') {
            this.firstElementChild.style.height = newValue === 'true' ? OPEN_WIDTH : CLOSE_WIDTH;
            this.firstElementChild.style.borderBottom = newValue === 'true' ? 'solid 1px white' : 'none';
        } else if (name === 'navigation') {
            const navData = JSON.parse(newValue);
            if (!Array.isArray(navData)) {
                this.navElem.innerHTML = '';
                return;
            }
            if (newValue !== oldValue) {
                this.navElem.innerHTML = this.buildNavItems(navData);
                if (this.selection) {
                    this.updateSelection(this.selection);
                }
            }
        } else if (name === 'selection' && newValue !== oldValue) {
            this.updateSelection(newValue);
        } else if (name === 'appname') {
            this.appName.innerText = newValue || '';
            this.appName.title = newValue || '';
            this.navigation = ''; //clear menu
            this.dispatchEvent(new CustomEvent('updateSubMenuSelection', { detail: { items: null, selection: null } })); // clear sub menu
        }
    }

    updateSelection = (path, elem) => {
        try {
            const itemsElems = [].slice.call(this.querySelectorAll('a'));
            itemsElems.forEach(item => item.classList.remove('selected'));
            elem = elem || itemsElems.find(item => item.dataset.path === path);
            if (elem) {
                if (!elem.dataset.children) {
                    elem.classList.add('selected');
                    this.hideNavArrows();
                    this.showNavArrow(elem, '#f2f2f2');
                    this.dispatchEvent(new CustomEvent('updateSubMenuSelection', { detail: { items: null, selection: null } }));
                }
            } else {
                const innerPathElem = itemsElems.find(item => {
                    if (item.dataset.children) {
                        const arrChildren = JSON.parse(item.dataset.children);
                        return !!arrChildren.find(child => child.path === path);
                    }
                    return false;
                });
                if (innerPathElem) {
                    this.dispatchEvent(new CustomEvent('updateSubMenuSelection', { detail: { items: JSON.parse(innerPathElem.dataset.children), selection: path } }));
                    this.showNavSelectionArrow(innerPathElem.dataset.path, innerPathElem);
                }
            }
        } catch (error) {
            console.log('app-header updateSelection failed', error);
        }
    } 

    buildNavItems = navData => {
        let childs = ``;
        const appPath = `${location.origin}/app/${history.state.appId}`;
        navData.forEach(data => {
            if (typeof data === 'object' && data !== null) {
                if (Array.isArray(data.children)) {
                    childs += `<a href=${appPath}${data.children.length ? data.children[0].path : ''} data-children=${escapeHtml(JSON.stringify(data.children || ''))}><span>${data.name}</span><div class="arrow-up"></div></a>`;
                } else {
                    const path = escapeHtml(data.path);
                    childs += `<a href=${appPath}${path} data-path=${path}><span>${data.name}</span><div style="border-bottom-color:#f2f2f2" class="arrow-up"></div></a>`;
                }
            }
        });
        return childs;
    }

    render() {
        this.innerHTML = `
        <div class="app-header-container">
            <menu-btn open="false"></menu-btn>
            <div class="vl"></div>
            <img class="app-header-logo" src=${k2Logo} alt="app header Logo">
            <span class="app-name"></span>
            <nav>
            </nav>
            <img class="user-icon" src=${userIcon} alt="User Icon">
        </div>`;
    }

    attachEventHandlers = () => {
        this.menuBtn.addEventListener('click', this.onMenuBtnClick);
        this.navElem.addEventListener('click', this.onNavClick);
        this.userBtn.addEventListener('click', this.onUserBtnClick);
        this.appName.addEventListener('click', this.navigateHome);
        this.logo.addEventListener('click', this.navigateHome);
    }

    disconnectedCallback() {
        this.menuBtn.removeEventListener('click', this.onMenuBtnClick);
        this.navElem.removeEventListener('click', this.onNavClick);
        this.userBtn.removeEventListener('click', this.onUserBtnClick);
        this.appName.removeEventListener('click', this.navigateHome);
        this.logo.removeEventListener('click', this.navigateHome);
    }

    onUserBtnClick = () => {
        this.userDetails.visible = true;
    }

    onNavClick = event => {
        const dataset = event.target.dataset;
        if (event.ctrlKey || event.metaKey) { return; } //open in new tab
        event.preventDefault();
        this.updateSelection(null, event.target);
        this.dispatchEvent(new CustomEvent('headerNavClick', { detail: this.showNavSelectionArrow(dataset.path, event.target) }));
    }

    navigateHome = () => {
        window.top.location.href = `${location.origin}/app/${i18n.getCurrentAppId()}`;
    }

    showNavSelectionArrow = (name, selectedElem) => {
        if (selectedElem.dataset.children) {
            const children = JSON.parse(selectedElem.dataset.children);
            this.hideNavArrows();
            if (Array.isArray(children) && children.length) {
                this.showNavArrow(selectedElem, 'white');
                return { name, children };
            }
        }
        return { name };
    }

    hideNavArrows = () => {
        const arrows = this.querySelectorAll('.arrow-up');
        arrows.forEach(elem => elem.style.display = 'none');
    }

    showNavArrow = (elem, borderColor) => {
        elem.querySelector('.arrow-up').style.display = 'block';
        elem.parentElement.parentElement.style.borderBottomColor = borderColor;
    }

    onMenuBtnClick = () => {
        const isOpen = this.menuBtn.open;
        this.dispatchEvent(new CustomEvent('menuBtnStateChange', { detail: { isOpen } }));
    }
}

customElements.define('app-header', AppHeader);