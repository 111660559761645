import '../webComponents/containers/modal-container';

class DialogModalService {
    constructor() {
        this.initDialog();
        this.cancelCallback = () => {};
    }

    initDialog() {
        this.modal = document.createElement('modal-container');
        this.message = document.createElement('P');
        this.message.setAttribute('style', 'color: #505050; font-size: 14px; user-select: text;');
        this.modal.appendChild(this.message);
        document.body.appendChild(this.modal);
        this.modal.addEventListener('modalSubmit', () => {
            this.submitCallback();
        });
    }

    show(title = '', message = '', onSubmit, onCancel) {
        this.modal.modaltitle = title;
        this.message.textContent = message;
        this.modal.visible = true;
        this.modal.submittext = 'ok';
        this.modal.showcancel = false;
        this.submitCallback = onSubmit ? onSubmit : () => {};
        if (onCancel) {
            this.cancelCallback = onCancel;
        }
    }
}

export const DialogService = new DialogModalService();