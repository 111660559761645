import * as fabric from './fabric';
import { i18n } from './I18nManager';
import { logout } from './auth';
import '../webComponents/display/widget-wrapper';
import { addDAPScript } from './generalServices';

let leftDrawer;
let appHeader;
let subMenu;

const appAPI = {
    registerNavigateTo: function (callback) {
        this.navigateTo = callback;
    },
    invokeFabricCommands: fabric.invokeCommands,
    invokeFabricWebService: fabric.invokeWS,
    i18n: {
        translate: i18n.translate,
        formatNumber: i18n.formatNumber,
        formatCurrency: i18n.formatCurrency,
        formatDateTime: i18n.formatDateTime,
    },
    expand,
    peak,
    openNewTab: url => window.open(url, '_blank').focus(),
    addDAPScript: addDAPScript,
};

export function getAppAPI(appHeader, applicationId) {
    window.k2api = getAPIObj(appHeader, history.state ? history.state.appId : applicationId || null); //we keep it in the fw as well for multipage app load issuel
    return window.k2api;
}

function getAPIObj(appHeader, applicationId) {
    appHeader = appHeader || window.top.document.querySelector('app-header');
    const k2api = {
        applicationId,
        setNavigationMenu: data => appHeader.navigation = data,
        logout: logout,
        getNewApi: appId => {
            i18n.fetchAppTraslations({ appId });
            return getAPIObj(appHeader, appId);
        },
        ...appAPI
    };
    return k2api;
}

export function getWidgetsApi() {
    return {
        createWidget
    };
}

function createWidget(rootElem, type, callback = () => { }, onUnmount = () => { }, initialData, useIframe = false, useDAP = false) {
    const widgetWrapper = document.createElement('WIDGET-WRAPPER');
    widgetWrapper.setAttribute('useiframe', useIframe);
    let iframeElem;
    if (useIframe) {
        iframeElem = createWidgetIframe(rootElem, widgetWrapper, useDAP, type);
    } else {
        rootElem.appendChild(widgetWrapper);
        widgetWrapper.setAttribute('wsrc', `/static/widgets/${type}/widget.js`);
    }
    let widgetRef;
    widgetWrapper.addEventListener('widgetLoaded', event => {
        const con = event.detail;
        const appWindow = useIframe ? iframeElem.contentWindow : rootElem.getRootNode().defaultView;
        const instance = appWindow.k2widgets[type].init(con, initialData);
        instance.api.render();
        widgetRef = instance.api.getWidgetRef();
        callback(widgetRef);
    });
    widgetWrapper.addEventListener('widgetUnmount', () => onUnmount(widgetRef));
}

function createWidgetIframe(rootElem, widgetWrapper, useDAP, type) {
    const iframeElem = document.createElement('IFRAME');
    rootElem.appendChild(iframeElem);
    iframeElem.id = 'k2WidgetIframe';
    iframeElem.style = 'width:100%; height:100%; border:0';
    iframeElem.srcdoc = "<div id='iframeRoot' style='height: 100%'></div>";
    iframeElem.onload = () => {
        iframeElem.contentDocument.body.style = 'margin: 0px; position: absolute; width: 100%;height: 100%';
        const innerRoot = iframeElem.contentDocument.querySelector('#iframeRoot');
        innerRoot.appendChild(widgetWrapper);
        iframeElem.contentWindow.k2api = rootElem.getRootNode().defaultView.k2api;
        if (useDAP) {
            addDAPScript(iframeElem.contentDocument.head);
        }
        widgetWrapper.setAttribute('wsrc', `/static/widgets/${type}/widget.js`);
    };
    return iframeElem;
}

export function expand(shouldExpand) {
    initExpandElems();
    appHeader.display = !shouldExpand;
    if (shouldExpand) {
        leftDrawer.parentElement.style.height = '100%';
        subMenu.style.display = 'none';
        leftDrawer.style.display = 'none';
    } else {
        leftDrawer.parentElement.style.height = 'calc(100% - 50px)';
        subMenu.style.display = 'block';
        leftDrawer.style.display = 'block';
    }
}

export function peak() {
    if (!appHeader.display) {
        expand(false);
        appHeader.dataset.peak = 'true';
    }
    if (leftDrawer.open === 'false') {
        leftDrawer.open = true;
        subMenu.style.display = 'block';
        appHeader.menuBtn.open = true;
    }
}

const initExpandElems = () => {
    leftDrawer = leftDrawer || document.querySelector('left-drawer');
    appHeader = appHeader || document.querySelector('app-header');
    subMenu = subMenu || document.querySelector('sub-menu');
};