export const FRAMEWORK_ID = 'K2FrontendsFramework';
export const SESSION_STORAGE_KEYS = {
    USER_NAME: 'fw-user-name',
};
export const AUTH_TYPES = {
    EXTERNAL: 'external',
    FABRIC: 'FABRIC'
};
export const COOKIES_KEYS = {
    AUTH_TYPE: 'authType',
    USE_LOGIN_PAGE: 'useLoginPage',
};
export const MAX_TIMEOUT_DELAY = 2147483647;

export const DAP_URLS = {
    DEV: 'https://whatfix.com/e09806a0-6d7a-11ec-b611-bacbbe07b880/embed/embed.nocache.js',
    PROD: 'https://cdn.whatfix.com/prod/e09806a0-6d7a-11ec-b611-bacbbe07b880/embed/embed.nocache.js'
};