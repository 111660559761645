class MenuBtn extends HTMLElement {

    set open(value) {
        this.setAttribute('open', value);
    }

    get open() {
        return this.getAttribute('open') === 'true';
    }

    static get observedAttributes() {
        return ['open'];
    }

    connectedCallback() {
        this.render();
        this.btnContainer = this.shadowRoot.querySelector('.container');
        this.attachEventHandlers();
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (!this.shadowRoot) {return; }
        if (name === 'open') {
            newValue === 'true' ? this.btnContainer.classList.add('change') : this.btnContainer.classList.remove('change');          
        }
    }

    attachEventHandlers = () => {
        this.btnContainer.addEventListener('click', this.onBtnClick);
    }

    disconnectedCallback() {
        this.btnContainer.removeEventListener('click', this.onBtnClick);
    }

    onBtnClick = () => {
        this.open = !this.open;
    }

    render() {
        const container = document.createElement('div');
        container.innerHTML = `
        ${getStyle()}
        <div class=${this.open ? `"container change"` : `"container"`}>
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
        </div>`;
        const shadowRoot = this.attachShadow({ mode: 'open' });
        shadowRoot.appendChild(container);
    }
}

function getStyle() {
    return `<style>
    .container {
        display: inline-block;
        cursor: pointer;
      }
      
      .bar1, .bar2, .bar3 {
        width: 30px;
        height: 2px;
        background-color: lightgray;
        margin: 8px 0;
        transition: 0.4s;
        pointer-events: none;
      }
      
      .change .bar1 {
        -webkit-transform: rotate(-45deg) translate(-9px, 6px);
        transform: rotate(-45deg) translate(-7px, 6px);
      }
      
      .change .bar2 {opacity: 0;}
      
      .change .bar3 {
        -webkit-transform: rotate(45deg) translate(-8px, -8px);
        transform: rotate(45deg) translate(-8px, -8px);
      }
    </style>`;
}
customElements.define('menu-btn', MenuBtn);