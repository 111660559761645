class Router {
    constructor() {
        this.appsContainer = document.querySelector('.micro-app-container');
    }

    setAppsData = data => this.appsData = data;
    getAppsData = () => this.appsData;
    
    getPathByAppId = appId => {
        if (!appId) {return  '';}
        return `static/${appId}`;
    }

    getMainPath = path => {
        try {
            if (path === '/' || path === '/app' || path === '/app/') {
                return '/';
            }
            let splittedPath = path.split('/');
            return splittedPath[2] ? `app/${splittedPath[2]}` : '/';
        } catch (error) {
            return '';
        }
    }

    getAppPath = appId => {
        try {
            const fullPath = location.href.replace(location.origin, ''); //includes hash and query
            const searchSplit = fullPath.split('?');
            const splitted = searchSplit[0].split('/');
            const appIdIndex = splitted.indexOf(appId);
            if (appIdIndex !== -1 && appIdIndex + 1 < splitted.length) {
                return `/${splitted.slice(appIdIndex + 1).filter(p => p && p !== location.search).join('/')}${location.search}`; // remove empty strings from array
            }
            return location.search;   
        } catch (error) {
            console.error(error);
            return location.search;
        }
    }

    onNavigate = (appId, menuItemId) => {
        const pathName = 'app/' + appId;
        window.history.pushState({ appId, menuItemId, appPath: '' }, appId, `${window.location.origin}/${pathName}`);
    }
 }

export const router = new Router();