class ModalContainer extends HTMLElement {
    constructor() {
        super();
    }

    get visible() {
        return this.hasAttribute('visible');
    }

    set visible(value) {
        if (value) {
            this.setAttribute('visible', '');
        } else {
            this.removeAttribute('visible');
        }
    }

    get modaltitle() {
        return this.getAttribute('modaltitle');
    }

    set modaltitle(value) {
        this.setAttribute('modaltitle', value);
    }

    get submittext() {
        return this.getAttribute('submittext');
    }

    set submittext(value) {
        this.setAttribute('submittext', value);
    }

    get canceltext() {
        return this.getAttribute('canceltext');
    }

    set canceltext(value) {
        this.setAttribute('canceltext', value);
    }

    get showcancel() {
        return this.getAttribute('showcancel');
    }

    set showcancel(value) {
        this.setAttribute('showcancel', value);
    }

    connectedCallback() {
        this.render();
        this.wrapper = this.shadowRoot.querySelector('.wrapper');
        this.cancelButton = this.shadowRoot.querySelector('.cancel');
        this.okButton = this.shadowRoot.querySelector('.ok');
        this.attachEventHandlers();
    }

    static get observedAttributes() {
        return ['visible', 'modaltitle', 'submittext', 'canceltext', 'showcancel'];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (name === 'modaltitle' && this.shadowRoot) {
            this.shadowRoot.querySelector('.modaltitle').textContent = newValue;
        } else if (name === 'submittext' && this.shadowRoot) {
            this.okButton.textContent = newValue;
        } else if (name === 'canceltext' && this.shadowRoot) {
            this.cancelButton.textContent = newValue;
        } else if (name === 'visible' && this.shadowRoot) {
            if (newValue === null) {
                this.shadowRoot.querySelector('.wrapper').classList.remove('visible');
                this.dispatchEvent(new CustomEvent('close'));
            } else {
                this.shadowRoot.querySelector('.wrapper').classList.add('visible');
                this.dispatchEvent(new CustomEvent('open'));         
            }
        } else if (name === 'showcancel' && this.shadowRoot) {
            this.shadowRoot.querySelector('.cancel').style.display = newValue === 'true' ? 'block' : 'none';
        }
    }

    render() {
        const wrapperClass = this.visible ? 'wrapper visible' : 'wrapper';
        const container = document.createElement('div');
        container.innerHTML = `
        ${getStyle()}
        <div class='${wrapperClass}'>
          <div class='modal'>
            <span class='modaltitle'>${this.modaltitle}</span>
            <hr>
            <div class='content'>
                <slot></slot>
            </div>
            <div class='button-container'>
              <button class='cancel'>${this.canceltext || 'cancel'}</button>
              <button class='ok'>${this.submittext || 'submit'}</button>
            </div>
          </div>
        </div>`;
        const shadowRoot = this.attachShadow({ mode: 'open' });
        shadowRoot.appendChild(container);
    }

    attachEventHandlers() {
        this.cancelButton.addEventListener('click', this.onCancel);
        this.okButton.addEventListener('click', this.onSubmit);
        this.wrapper.addEventListener('mousedown', this.onWrapperClick);
    }

    disconnectedCallback() {
        this.wrapper.removeEventListener('mousedown', this.onWrapperClick);
        this.okButton.removeEventListener('click', this.onSubmit);
    }

    onSubmit = () => {
        this.dispatchEvent(new CustomEvent('modalSubmit'));
        this.removeAttribute('visible');
    } 

    onCancel = () => {
        this.dispatchEvent(new CustomEvent('modalCancel'));
        this.removeAttribute('visible');
    } 
    onWrapperClick = e => {
        if (!e.target.classList.contains('wrapper') && !e.target.classList.contains('cancel')) { return; }
        this.dispatchEvent(new CustomEvent('modalCancel'));
        this.removeAttribute('visible');
    };
}

function getStyle() {
    return `<style>
        .wrapper {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.35);
            opacity: 0;
            visibility: hidden;
            transform: scale(1.1);
            transition: visibility 0s linear .25s,opacity .25s 0s,transform .25s;
            z-index: 100;
        }
        .visible {
            opacity: 1;
            visibility: visible;
            transform: scale(1);
            transition: visibility 0s linear 0s,opacity .25s 0s,transform .25s;
        }
        hr {
            margin: 7px 0px;
            border: 1px solid #EAEAEA;
        }
        .modal {
            font-family: Helvetica;
            font-size: 14px;
            padding: 10px 15px 5px 15px;
            background-color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            min-width: 400px;
            border-radius: 4px;
            max-width: calc(100vw - 130px);
            max-height: calc(100vh - 130px);
            overflow: auto;
        }
        .modaltitle {
            font-size: 20px;
            font-weight: 500;
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            letter-spacing: 0.0075em;
            color: #21bedd;
        }
        .button-container {
            text-align: right;
        }
        button {
            padding: 3px;
            color: #21bedd;
            cursor: pointer;
            font-size: 14px;
            min-width: 45px;
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            font-weight: bold;
            line-height: 1.75;
            text-transform: uppercase;
            background-color: transparent;
            border: none;
            border-radius: 4px;
        }
        button:hover {
            color: rgba(33, 190, 221, 0.5);
        }
        p, div, span, button, li{
            user-select: none;
        }
        </style>`;
}

window.customElements.define('modal-container', ModalContainer);