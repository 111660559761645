import './styles/change-password.scss';
import { i18n } from '../../services/I18nManager';

class ChangePassword extends HTMLElement {

    get failtxt() {
        return this.getAttribute('failtxt');
    }

    set failtxt(value) {
        this.setAttribute('failtxt', value);
    }

    connectedCallback() {
        this.render();
        this.newPassInput = this.querySelector('#newPass');
        this.confirmPassInput = this.querySelector('#confirmPass');
        this.failMsg = this.querySelector('.fail-msg');
        this.attachEventHandlers();
    }

    static get observedAttributes() {
        return ['failtxt'];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (!this.innerHTML) { return; }
        if (name === 'failtxt') {
            this.failMsg.style.visibility = 'visible';
            this.failMsg.innerText = newValue;
        }
    }

    allowSubmit = isAllow => { // implement
        if (isAllow) {
            this.parentElement.okButton.disabled = false;
            this.parentElement.okButton.style.cursor = "pointer";
            this.parentElement.okButton.style.opacity = 1;
        } else {
            this.parentElement.okButton.disabled = true;
            this.parentElement.okButton.style.cursor = "not-allowed";
            this.parentElement.okButton.style.opacity = 0.5;
        }
    }

    getPassword = () => {
        return this.newPassInput.value;
    }

    attachEventHandlers = () => {
        this.newPassInput.addEventListener('keyup', event => {
            this.newPassValue = event.srcElement.value;
            this.comparePasswords();
        });
        this.confirmPassInput.addEventListener('keyup', event => {
            this.confirmPassValue = event.srcElement.value;
            this.comparePasswords();
        });
    }

    comparePasswords = () => {
        if (this.newPassValue && this.confirmPassValue) {
            if (this.newPassValue === this.confirmPassValue) {
                this.failtxt = '';
                this.allowSubmit(true);
                return;
            }
            this.failtxt = i18n.translate('changePassword.notMatch');
            this.allowSubmit(false);
            return;
        }
        this.failtxt = '';
        this.allowSubmit(false);
    }

    reset() {
        this.newPassInput.value = '';
        this.confirmPassInput.value = '';
        this.newPassValue = '';
        this.confirmPassValue = '';
        this.failtxt = '';
        this.allowSubmit(false);        
        setTimeout(() => {
            this.newPassInput.focus();
        }, 250);
    }

    render() {
        this.innerHTML = `
        <div class="change-pass-container">
            <p class="fail-msg"></p>
            <label>${i18n.translate('changePassword.newPass')}</label>
            <input id="newPass" type="password" title=${i18n.translate('changePassword.newPass')}>
            <div class="seperator"></div>
            <label>${i18n.translate('changePassword.confirm')}</label>
            <input id="confirmPass" type="password" title=${i18n.translate('changePassword.confirm')}>
        </div>`;
    }
}
customElements.define('change-password', ChangePassword);