export function escapeHtml(unsafe) {
    if (!unsafe) { return unsafe; }
    return unsafe
      //.replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/ /g, "&nbsp;")
      .replace(/'/g, "&#039;");
  }
  
  export function unEscapeHtml(unsafe) {
    if (!unsafe) { return unsafe; }
    return unsafe
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&nbsp;/g, ' ')
      .replace(/&#039;/g, "'");
  }

  export function setPluginDefaultFont(font, plugin) {
    if (!isIframeOnSameOrigin(plugin)) { return; }
    plugin.contentWindow.document.fonts.add(font);
    let s = plugin.contentWindow.document.createElement("style");
    s.type = 'text/css';
    let css = '* { font-family: "Roboto", sans-serif; }';
    s.appendChild(plugin.contentWindow.document.createTextNode(css));
    plugin.contentWindow.document.head.appendChild(s);
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const cookieVal = parts.pop().split(';').shift();
    return cookieVal;
  }
}

export function isIframeOnSameOrigin(iframeElem) {
  return !!iframeElem.contentDocument;
}

export function cleanPath(path) { //remove query params
  if (!path) { return path; }
  path = path.startsWith('/') ? path : `/${path}`;
  const urlObj = new URL(location.origin + path);
  urlObj.search = '';
  const clean = urlObj.toString().replace(location.origin, '');
  return clean;
}