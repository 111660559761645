import * as Network from './network';

const RESPONSE_FORMAT = 'json';

/**
 * used to invoke fabric commands - one or many.
 * @param {string|object|string[]|object[]} cmds commands to invoke. 
 * object format - { command: "string_command ?=?", "params": ["optional", "params"]}
 * string format - complete command as a string
 */
export async function invokeCommands(cmds) {
    if (!cmds) { throw 'fabric commands wasn`t provided'; }
    return invokeMultipleCommands(Array.isArray(cmds) ? cmds : [cmds]);
}

function invokeMultipleCommands(arrCmds) {
    if (!arrCmds.length) { throw 'invalid array of fabric commands'; }

    const formatedCommands = [];
    arrCmds.forEach(cmd => {
        if (typeof cmd === 'string') {
            formatedCommands.push({ command: cmd });
        } else if (typeof cmd === 'object' && cmd !== null && cmd.command) {
            formatedCommands.push({
                command: cmd.command,
                params: cmd.params,
            });
        }
    });
    const data = Network.invokeHTTPRequest(`api/fabric-commands`, Network.POST_REQUEST, { commands: formatedCommands }, null, getCustomHeaders());
    return data;
}

/**
 * invoke fabric web service
 * @param {string} name web service name
 * @param {object} paramsObj optional, will be sent as query strings for get and body for over verbs
 * @param {string} verb optional, default value is GET
 * @param {string} version optional, fabric api version, default value latest version
 */
export function invokeWS(name, paramsObj = null, verb = Network.GET_REQUEST, version) {
    if (!name) { throw 'name wasn`t provided'; }
    const url = version ? `api/${version}/${name}` : `api/${name}`;
    const data = Network.invokeHTTPRequest(url, verb, paramsObj, getQSParams(paramsObj, verb), getCustomHeaders(verb));
    return data;
}

function getQSParams(paramsObj, verb) {
    if (verb === Network.GET_REQUEST) {
        paramsObj = paramsObj || {};
        return { format: RESPONSE_FORMAT, ...paramsObj };
    }
    return null;
}

function getCustomHeaders(verb) {
    if (verb === Network.GET_REQUEST) {
        return {};
    }
    return { 'Content-Type': 'application/json' };
}