import * as Network from './network';

const DEFAULT_KEY = 'default';
const DEFAULT_LANG_CODE = 'en-US';
const DEFAULT_CURRENCY = 'USD';

class I18nManager {
    constructor() {
        this.langCode = DEFAULT_LANG_CODE;
        this.currency = DEFAULT_CURRENCY;
        this.currentAppKey = DEFAULT_KEY;
    }

    init = async appsData => {
        try {
            this.data = await Network.invokeHTTPRequest('static/translations.json'); //all apss translations
            if (!this.data) {
                console.error('FAILED TO LOAD FRAMWORK TRANSLATIONS FILE');
                this.data = { default: {} };
            }
            await this.initAppsTranslations(appsData);
        } catch (error) {
            console.error(error);
        }
    }

    initAppsTranslations = async (appsData) => {
        await Promise.allSettled(appsData.map(this.fetchAppTraslations));
    }

    fetchAppTraslations = async (app) => {
        try {
            if (!app || this.data[app.appId]) return;
            const translations = await Network.invokeHTTPRequest(`static/${app.appId}/translations.json`, Network.GET_REQUEST, null, null, {}, `Missing traslation file for app: ${app.appId}`);
            if (!(translations instanceof Error)) {
                this.data[app.appId] = translations;
            }   
        } catch (error) {
            console.error(error);
        }
    }

    setLangCode = lang => this.langCode = lang
    setCurrency = currency => this.currency = currency
    setCurrentAppKey = curr => this.currentAppKey = curr;

    translate = (key, params = [], appId = null) => {
        if (!this.data || !key) {
            return key;
        }
        appId = appId || this.getCurrentAppId();

        if (this.data[appId]) {
            if (this.data[appId][key]) {
                return this.addTranslationParams(this.data[appId][key], params);
            }
        }

        if (this.data[DEFAULT_KEY] && this.data[DEFAULT_KEY][key]) {
            return this.addTranslationParams(this.data[DEFAULT_KEY][key], params);
        }

        return key;
    }

    addTranslationParams = (value, params) => {
        if (!params || !params.length) {
            return value;
        }

        const splitted = value.split('{}');
        if (splitted.length <= 1) {
            return value;
        }

        let output = ``;
        splitted.forEach((section, index) => {
            output += `${section}${params[index] || ''}`;
        });

        return output;
    }

    formatNumber = (number, langCode = this.langCode, extraOptions = {}) => { //extraOptions from Intl API 
        try {
            return new Intl.NumberFormat(langCode, extraOptions).format(number);
        } catch (error) {
            console.error(error);
            return number;
        }
    }

    formatCurrency = (number, currencyCode = this.currency, langCode = this.langCode) => {
        try {
            return new Intl.NumberFormat(langCode, { style: 'currency', currency: currencyCode }).format(number);
        } catch (error) {
            console.error(error);
            return number;
        }
    }

    formatDateTime = (date, langCode = this.langCode, extraOptions = {}) => { //extraOptions from Intl API 
        try {
            return new Intl.DateTimeFormat([langCode, DEFAULT_LANG_CODE], extraOptions).format(date); // add fallback to default lang   
        } catch (error) {
            console.error(error);
            return date;
        }
    }

    getCurrentAppId = () => {
        if (history.state && history.state.appId) {
            return history.state.appId;
        }

        if (this.currentAppKey) {
            return this.currentAppKey;
        }

        if (window.k2api && window.k2api.applicationId) {
            return window.k2api.applicationId;
        }

        return DEFAULT_KEY;
    }
}

export const i18n = new I18nManager();