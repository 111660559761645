import { DAP_URLS } from "../../globals";
import { invokeHTTPRequest } from "./network";

export function disableKeyBoardSave(targetElem, useCapture = false) {
    if (!targetElem) { return; }
    targetElem.addEventListener('keydown', function (evt) {
        evt = evt || window.event;
        if (evt.ctrlKey || evt.metaKey) {
            if (evt.key === 's') { //ctrl + s
                evt.preventDefault();
            }
        }
    }, useCapture);
}

export function addDAPScript(target) {
    if (!target) { return; }
    let dapMode = localStorage.getItem('DAP') || '';
    dapMode = dapMode.toLowerCase();
    if (dapMode === 'off') { return; }
    const script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.src = dapMode === 'dev' ? DAP_URLS.DEV : DAP_URLS.PROD;
    target.appendChild(script);
}

export function fetchApps() {
    return new Promise((resolve, reject) => {
        Promise.allSettled([
            invokeHTTPRequest('static/apps.base.json'),
            invokeHTTPRequest('static/apps.json')])
            .then(values => {
                resolve(parseApps(values));
            })
            .catch(err => {
                reject(err);
            });
    });
}
function parseApps(values) {
    const appsMap = new Map();
    values.forEach((res) => {
        if (res.status === 'fulfilled' && Array.isArray(res.value)) {
            res.value.forEach(app => appsMap.set(app.name, app));
        }
    });
    return Array.from(appsMap.values()).filter(app => !app.hidden);    
}
