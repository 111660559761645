import './styles/login.scss';
import { showParticles } from '../../services/particlesService';
import k2Logo from '../../../../static/loginLogo.svg';
import { i18n } from '../../services/I18nManager';

class LoginContainer extends HTMLElement {

    get failtxt() {
        return this.getAttribute('failtxt');
    }

    set failtxt(value) {
        this.setAttribute('failtxt', value);
    }

    set loading(value) {
        this.setAttribute('loading', value);
    }

    get loading() {
        return this.getAttribute('loading');
    }

    connectedCallback() {
        document.title = `${i18n.translate('browser.titlePrefix')}Login`;
        this.render();
        this.loginBtn = this.querySelector('.common-btn');
        this.usernameInput = this.querySelector('#username');
        this.passwordInput = this.querySelector('#password');
        this.failMsg = this.querySelector('.fail-msg');
        this.btnLoader = this.querySelector('.k2-btn-loader');
        this.attachEventHandlers();
        showParticles('loginContainer');
    }

    static get observedAttributes() {
        return ['failtxt', 'loading'];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (!this.innerHTML) { return; }
        if (name === 'failtxt') {
            this.failMsg.style.display = 'block';
            this.failMsg.innerText = newValue;
        } else if (name === 'loading') {
            if (newValue === 'true') {
                this.btnLoader.style.display = 'inline-block';
                this.loginBtn.style.pointerEvents = 'none';
            } else {
                this.btnLoader.style.display = 'none';
                this.loginBtn.style.pointerEvents = 'auto';
            }
        }
    }

    render() {
        this.innerHTML = `
        <div id="loginContainer" class="login-container">
            <img class="logo" src=${k2Logo} alt="K2VIEW Logo">
            <form id="loginform" class="form k2-card">
                <h1>${i18n.translate("login.modal.title")}</h1>
                <p class="fail-msg"></p>
                <label>Username</label>
                <input id="username" type="text" name="username" autocapitalize="off" title=${i18n.translate('login.modal.userName')}>
                <div class="seperator"></div>
                <label>Password</label>
                <input id="password" type="password" name="password" autocapitalize="off" title=${i18n.translate('login.modal.Password')}>
                <button type="submit" class="common-btn">
                    <div style="display:none" class="k2-btn-loader"></div>
                    ${i18n.translate('login.modal.button')}
                </button>
            </form>
        </div>`;
    }

    attachEventHandlers = () => {
        this.loginBtn.addEventListener('click', this.onLoginClick);
        document.addEventListener('keydown', this.onkeydown);
    }

    disconnectedCallback() {
        this.loginBtn.removeEventListener('click', this.onLoginClick);
        document.removeEventListener('keydown', this.onkeydown);
    }

    
    onkeydown = evt => {
        evt = evt || window.event;
        if (evt.keyCode == 13) { //Enter clicked
            this.onLoginClick(evt);
        }
    }

    onLoginClick = (evt) => {
        evt.preventDefault();
        this.dispatchEvent(new CustomEvent('loginClick', { detail: { username: this.usernameInput.value, password: this.passwordInput.value } }));
    }
}
customElements.define('login-container', LoginContainer);