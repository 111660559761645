class LoaderOverlay extends HTMLElement {
    connectedCallback() {
        this.render();
    }

    render() {
        this.innerHTML = `
        ${getStyle()}
        <div class="overlay-container">
            <div class="k2-loader"></div>
            <h1 class="loading-title">Loading...</h1>
        </div>`;
    }
}

function getStyle() {
    return `<style>
    .overlay-container {
        width: 100%;
        height: 100%;
        background-color: #eaeaea;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .loading-title {
        margin-top: 20px;
    }
    </style>`;
}


customElements.define('loader-overlay', LoaderOverlay);