import logoutIcon from '../../../../static/signout.svg';
import passwordIcon from '../../../../static/change_password.svg';
import { logout } from '../../services/auth';
import { invokeCommands } from '../../services/fabric';
import { changePasswordService } from '../../services/ChangePasswordService';
import { AUTH_TYPES, COOKIES_KEYS, SESSION_STORAGE_KEYS } from '../../../globals';
import { i18n } from '../../services/I18nManager';
import { DialogService } from '../../services/DialogModalService';
import { getCookie } from '../../../utils';

class UserDetails extends HTMLElement {
    constructor() {
        super();
    }

    get visible() {
        return this.getAttribute('visible');
    }

    set visible(value) {
        this.setAttribute('visible', value);
    }

    connectedCallback() {
        this.render();
        this.wrapper = this.shadowRoot.querySelector('.wrapper');
        this.tooltipCon = this.shadowRoot.querySelector('.tooltip-con');
        this.changePassBtn = this.shadowRoot.querySelector('#changePass');
        this.logoutBtn = this.shadowRoot.querySelector('#logout');
        this.userNameLabel = this.shadowRoot.querySelector('.user-name');
        this.attachEventHandlers();
        this.fetchUserName();
        this.checkChangePasswordVisibility();
    }
    
    checkChangePasswordVisibility = () => {
        if (getCookie(COOKIES_KEYS.AUTH_TYPE) === AUTH_TYPES.EXTERNAL) {
            this.changePassBtn.style.display = 'none';
            this.tooltipCon.style.height = '92px';
        }
    }

    fetchUserName = async () => {
        try {
            const data = await invokeCommands('set username');
            const userName = data.results[0].resultSet.rows[0][0];
            sessionStorage.setItem(SESSION_STORAGE_KEYS.USER_NAME, userName);
            this.userNameLabel.textContent = userName;
        } catch (error) {
            console.error(error);
            sessionStorage.setItem(SESSION_STORAGE_KEYS.USER_NAME, '');
        }
    }

    static get observedAttributes() {
        return ['visible'];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (!this.shadowRoot) { return; }
        if (name === 'visible') {
            if (newValue === 'true') {
                this.wrapper.style.visibility = 'visible';
                this.tooltipCon.style.maxHeight = '200px';
            } else {
                this.tooltipCon.style.maxHeight = '0px';
                setTimeout(() => {
                    this.wrapper.style.visibility = 'hidden';
                }, 300);
            }
        }
    }

    attachEventHandlers() {
        this.wrapper.addEventListener('click', () => {
            this.visible = false;
        });
        this.logoutBtn.addEventListener('click', logout);
        this.changePassBtn.addEventListener('click', this.onChangePasswordClick);
    }

    disconnectedCallback() {
        this.logoutBtn.removeEventListener('click', logout);
        this.changePassBtn.removeEventListener('click', this.onChangePasswordClick);
    }

    onChangePasswordClick = () => {
        changePasswordService.show(async password => {
            const userName = sessionStorage.getItem(SESSION_STORAGE_KEYS.USER_NAME);
            const result = await invokeCommands(`alter user '${userName}' with password '${password}';`);
            if (result instanceof Error) {
                DialogService.show(i18n.translate('changePassword.fail.title'), result);
                return;
            }
            DialogService.show(i18n.translate('changePassword.success.title'), i18n.translate('changePassword.success.message'));
        });
    }

    render() {
        const container = document.createElement('div');
        container.innerHTML = `
        ${getStyle()}
        <div class="wrapper">
            <div class="tooltip-con">
                <div class="sub-con">
                    <span class="user-name"></span>
                    <hr>
                    <div class="btns-con">
                        <div id="changePass" class="btn">
                            <img class="icon" src=${passwordIcon} alt=${i18n.translate("changePassword.title")}>
                            <span>${i18n.translate("changePassword.title")}</span>
                        </div>
                        <div id="logout" class="btn">
                            <img class="icon" src=${logoutIcon} alt=${i18n.translate("btn.logout")}>
                            <span>${i18n.translate("btn.logout")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>`;
        const shadowRoot = this.attachShadow({ mode: 'open' });
        shadowRoot.appendChild(container);
    }
}

function getStyle() {
    return `<style>
        .wrapper {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            visibility: hidden;
            z-index: 99;
        }
        .user-name {
            display: block;
            margin-top: 10px;
            color: var(--primary-color);
            margin-left: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .sub-con {
            overflow: hidden;
            height: 100%;
        }
        .icon {
            height: 20px;
            margin-left: 5px;
        }
        .btn {
            display: flex;
            flex-direction: row;
            height: 33px;
            align-items: center;
            padding-left: 5px;
            font-size: 14px;
            cursor: pointer;
            margin-bottom: 5px; 
            transition: background-color 0.35s ease;
        }
        .btn:hover {
            background-color: var(--list-hover-bg);
        }
        span {
            margin-left: 5px;
            margin-top: 3px;
        }
        .tooltip-con {
            background: #ffffff;
            border: 1px solid #D4D4D4;
            position: absolute;
            display: block;
            width: 200px;
            height: 130px;
            top: 53px;
            right: 10px;
            border-radius: 5px;
            box-shadow: 0 4px 5px 3px rgba(0, 0, 0, 0.2);
            max-height: 0px;
            transition: max-height 0.3s ease-in-out;
        }
        .tooltip-con:after, .tooltip-con:before {
            bottom: 100%;
            right: 13px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }
        .tooltip-con:after {
            border-color: rgba(255, 255, 255, 0);
            border-bottom-color: #ffffff;
            border-width: 10px;
            margin-left: -10px;
        }
        .tooltip-con:before {
            border-color: rgba(212, 212, 212, 0);
            border-bottom-color: #D4D4D4;
            border-width: 11px;
            margin-left: -11px;
        }
        </style>`;
}

window.customElements.define('user-details', UserDetails);