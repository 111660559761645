import '../webComponents/containers/modal-container';
import '../webComponents/containers/change-password';
import { i18n } from './I18nManager';

class ChangePasswordService {
    constructor() {
    }

    initDialog() {
        this.modal = document.createElement('modal-container');
        this.changePassElem = document.createElement('change-password');
        this.modal.modaltitle = i18n.translate('changePassword.title');
        this.modal.submittext = i18n.translate('btn.change');
        this.modal.appendChild(this.changePassElem);
        document.body.appendChild(this.modal);
        this.modal.addEventListener('modalSubmit', () => {
            this.submitCallback(this.changePassElem.getPassword());
        });
    }

    show(onSubmit, onCancel) {
        this.changePassElem.reset();
        this.modal.visible = true;
        this.submitCallback = onSubmit ? onSubmit : () => {};
        if (onCancel) {
            this.cancelCallback = onCancel;
        }
    }
}

export const changePasswordService = new ChangePasswordService();