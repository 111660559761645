import { MAX_TIMEOUT_DELAY } from "../../globals";
import { sleep } from "../../utils";
import { DialogService } from "./DialogModalService";
import { i18n } from "./I18nManager";

const BE_URL = `${window.location.origin}/`;
const ERR_NO_CONNECTION = 'ERR_CONNECTION_REFUSED';
const UNAUTHORIZED_STATUS_CODE = 401;
export const INTERNAL_SERVER_ERR_STATUS_CODE = 500;
export const GET_REQUEST = 'GET';
export const POST_REQUEST = 'POST';
export const DELETE_REQUEST = 'DELETE';
export const PUT_REQUEST = 'PUT';

export async function invokeHTTPRequest(route, method = GET_REQUEST, bodyParams = null, qsParams = null, customHeaders = {}, customErrMsg) {
    let response;
    try {
        response = await fetch(getURL(route, method, qsParams), getFetchObject(method, bodyParams, customHeaders));
        const contentType = response.headers.get('content-type');
        if (!isValidContentType(contentType)) {
            throw new Error(`invalid response content-type ${contentType}`);
        }
        const data = await response.json();
        if (data && data.url && (response.status === 401 || /* authenticate redirect*/
            (route.endsWith('logout') && response.status === 200))) { //logout redirect
            location.replace(data.url);
            await sleep(MAX_TIMEOUT_DELAY); //wait for reload
        } else if (response.status < 200 || response.status > 399) { //Error codes 
            throw new Error(typeof data === 'string' ? data : JSON.stringify(data));
        }
        return data;
    } catch (error) {
        error.statusCode = response ? response.status : ERR_NO_CONNECTION;
        error.name = `${error.name} ${error.statusCode}`;
        error.isError = true; // if the error was thrown in a different window/frame/iframe than the instanceof Error check will return false
        console.error(customErrMsg || error);
        if (await onNetworkError(error, response, route)) {
            return error;
        }
    }
}

function isValidContentType(contentType) {
    return (contentType && (contentType.startsWith('application/json') || contentType.startsWith('application/problem+json')));
}

async function onNetworkError(error, response, route) {
    if (error && error.statusCode === UNAUTHORIZED_STATUS_CODE && !route.includes('authenticate')) { // error msgs will never return anything
        await showErrorDialog(i18n.translate('network.timeout.title'), i18n.translate('network.timeout.msg'));
    } else if (!response && location.pathname.startsWith('/app/') && !location.pathname.startsWith('/app/login')) {
        await showErrorDialog(i18n.translate('network.disconnected.title'), i18n.translate('network.disconnected.msg'));
    }
    return true;
}

async function showErrorDialog(title, msg) {
    DialogService.show(title, msg, logout, logout);
    await sleep(MAX_TIMEOUT_DELAY); // we don't want to see the child app msg along with FW
}

function logout() {
    location.pathname = '';
    location.reload();
}

function getURL(route, method, params) {
    let url = BE_URL + route;
    if (params) {
        url += `?${new URLSearchParams(params).toString()}`;
    }
    return url;
}

function getFetchObject(method, params, customHeaders) {
    const fetchObj = {
        method: method,
        headers: {
            Accept: '*/*',
            ...customHeaders,
        },
    };

    if (params && method !== GET_REQUEST) {
        fetchObj.body = typeof params === 'object' ? JSON.stringify(params) : params;
    }

    return fetchObj;
}
