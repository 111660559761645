import './styles/left-drawer.scss';
import { logout } from '../../services/auth';
import { i18n } from '../../services/I18nManager';

const OPEN_WIDTH = `250px`;
const CLOSE_WIDTH = `0px`;

class LeftDrawer extends HTMLElement {

    set menu(value) {
        this.setAttribute('menu', value);
    }

    get menu() {
        return this.getAttribute('menu');
    }

    set open(value) {
        this.setAttribute('open', value);
    }

    get open() {
        return this.getAttribute('open');
    }

    get selection() {
        return this.getAttribute('selection');
    }

    set selection(value) {
        this.setAttribute('selection', value);
    }

    static get observedAttributes() {
        return ['menu', 'open', 'selection'];
    }

    connectedCallback() {
        this.docTitlePrefix = i18n.translate("browser.titlePrefix");
        this.render();
        this.container = this.querySelector('.left-drawer-container');
        this.listParent = this.querySelector('.list-parent');
        this.logoutBtn = this.querySelector('span');
        this.addListeners();
    }

    addListeners() {
        this.logoutBtn.addEventListener('click', logout);
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (!this.innerHTML) { return; }
        if (name === 'open') {
            this.toggleOpen(newValue === 'true');
        } else if (name === 'menu') {
            const menuData = newValue ? JSON.parse(newValue) : [];
            this.buildMenu(menuData);
        } else if (name === 'selection') {
            this.selectionDisplayName = '';
            if (!newValue) {
                this.unselectAllItems();
                document.title = this.docTitlePrefix;
                return;
            }

            if (oldValue && oldValue !== newValue) {
                this.unselectItemById(oldValue);
            }

            if (!newValue.includes('listItem')) {
                try {
                    const itemElem = this.querySelector(`li[data-app-id=${newValue}]`);
                    this.selection = itemElem.id;
                    this.selectionDisplayName = itemElem.dataset.name;
                    i18n.setCurrentAppKey(itemElem.dataset.appId);
                    document.title = this.docTitlePrefix + itemElem.dataset.name;
                } catch (error) {
                    console.log(error);
                }
                return;
            }

            const selectedItem = this.querySelector('#' + newValue);
            if (selectedItem && !selectedItem.classList.contains('bg-selected')) {
                this.selectItem(selectedItem);
                this.selectionDisplayName = selectedItem.dataset.name;
                i18n.setCurrentAppKey(selectedItem.dataset.appId);
                document.title = this.docTitlePrefix + selectedItem.dataset.name;
            }
        }
    }

    toggleOpen = (isOpen) => {
        if (isOpen) {
            this.container.style.maxWidth = OPEN_WIDTH;
        } else {
            this.container.style.maxWidth = CLOSE_WIDTH;
        }
    }

    unselectAllItems = () => {
        const items = this.querySelectorAll('li');
        items.forEach(item => {
            item.classList.remove('bg-selected');
        });
        i18n.setCurrentAppKey('');
    }

    buildMenu = menuData => {
        this.listParent.innerHTML = ``;
        menuData.forEach((item, index) => {
            const listItem = document.createElement('li');
            listItem.setAttribute('class', 'list-option');
            listItem.setAttribute('id', 'listItem' + index);
            listItem.textContent = item.name;
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    listItem.dataset[key] = item[key];
                }
            }
            listItem.addEventListener('click', this.onMenuItemClick);
            this.listParent.append(listItem);
        });
    }

    unselectItemById = (itemId) => {
        if (!itemId) { return; }
        const lastSelection = document.getElementById(itemId);
        if (lastSelection) {
            lastSelection.classList.remove('bg-selected');
        }
    }

    selectItem = (selectedItem) => {
        selectedItem.classList.add('bg-selected');
    }

    onMenuItemClick = (event) => {
        event.stopPropagation();
        if (event.ctrlKey || event.metaKey) { //on ctrl + click open new tab with the app
            window.open(`${location.origin}/app/${event.target.dataset.appId}`, '_blank');
            return;
        }
        let dispatchData = null;
        if (this.selection !== event.target.id) {
            this.selection = event.target.id;
            this.selectItem(event.target);
            dispatchData = { ...event.target.dataset, itemId: event.target.id };
            this.dispatchEvent(new CustomEvent('menuItemSelectionChange', { detail: dispatchData }));
        }
    }

    render() {
        this.innerHTML = `
        <div class="left-drawer-container" style=max-width:${this.open === 'true' ? OPEN_WIDTH : CLOSE_WIDTH}>
            <ul class="list-parent"></ul>
            <span>${i18n.translate("btn.logout")}</span>
        </div>`;
    }
}

customElements.define('left-drawer', LeftDrawer);