import '../index.scss';
import './webComponents/containers/app-wireframe';
import './webComponents/display/loader-overlay';
import './webComponents/containers/login';
import { router } from './router/Router';
import { FRAMEWORK_ID } from '../globals';
import { INTERNAL_SERVER_ERR_STATUS_CODE } from './services/network';
import { i18n } from './services/I18nManager';
import * as auth from './services/auth';
import { disableKeyBoardSave, addDAPScript, fetchApps } from './services/generalServices';
import { getWidgetsApi } from './services/appAPI';

let appRoot;
let wireframe;
let login;
let originLocationPath = '';
let appsData;
let loaderOverlay;

onload = async () => {
    try {
        window.applicationId = FRAMEWORK_ID;
        showLoader();
        appsData = await fetchApps();
        await i18n.init(appsData); //load translations
        disableKeyBoardSave(window, true);
        if (location.pathname.startsWith('/app') && !location.pathname.startsWith('/app/login')) {
            originLocationPath = location.href.replace(location.origin, '');
        }
        handleAuth();
    } catch (error) {
        console.error(error);
    }
};

async function handleAuth() {
    const authRes = await auth.authenticate({ currLocation: location.href });
    if (authRes.response === auth.LOGIN_SUCCESS) {
        onLoginSuccess();
    } else if (authRes.statusCode === INTERNAL_SERVER_ERR_STATUS_CODE) {
        location.replace('/static/authError.html');
    } else {
        login = document.createElement('LOGIN-CONTAINER');
        loaderOverlay.remove();
        appRoot.appendChild(login);
        login.addEventListener('loginClick', onLoginClick);
        window.history.replaceState({}, 'login', `${location.origin}/app/login`);
    }
}

function showLoader() {
    appRoot = document.querySelector('.app-root');
    loaderOverlay = document.createElement('LOADER-OVERLAY');
    appRoot.appendChild(loaderOverlay);
}

async function onLoginClick(event) {
    try {
        login.loading = true;
        const authRes = await auth.authenticate(event.detail);
        if (authRes.isError) {
            throw authRes.message;
        } else if (authRes.response !== auth.LOGIN_SUCCESS) {
            throw authRes.response;
        }
        appRoot.removeChild(login);
        login.loading = false;
        login = null;
        window.history.replaceState({}, '', `${location.origin}${originLocationPath}`);
        onLoginSuccess();
    } catch (error) {
        login.loading = false;
        login.failtxt = error;
    }
}

async function onLoginSuccess() {
    try {
        wireframe = document.createElement('APP-WIREFRAME');
        router.setAppsData(appsData);
        loaderOverlay.remove();
        appRoot.appendChild(wireframe);
        wireframe.menu = appsData;
        const mainPath = router.getMainPath(window.location.pathname);
        let appId = getAppIdFromMainPath(mainPath, appsData);
        if (appId) {
            const appMainPath = router.getPathByAppId(appId);
            const appInnerPath = router.getAppPath(appId);
            wireframe.setAppRoute(appMainPath, appInnerPath, appId);
            wireframe.appselection = appId;
            window.history.pushState({ appId, menuItemId: wireframe.getSelectedMenuItem(), appPath: appInnerPath }, appId, `${location.origin}/${mainPath}`);
        }
        window.k2widgets = getWidgetsApi();
        addDAPScript(document.head);
    } catch (error) {
        console.error('FAILED TO LOAD ', error);
    }
}

function getAppIdFromMainPath(mainPath, appsData) {
    try {
        let appId = (!mainPath || mainPath === '/') ? appsData[0].appId : mainPath.replace('app/', '');
        if (!appsData.find(data => data.appId === appId)) {
            appId = appsData[0].appId;
        }
        return appId;
    } catch (error) {
        console.log('getAppIdFromMainPath', error);
        return '';
    }
}

window.onpopstate = () => {
    if (!wireframe || !history.state || !history.state.menuItemId) { return; }
    wireframe.appselection = window.history.state.menuItemId;
    const path = router.getPathByAppId(window.history.state.appId);
    const url = `${window.location.origin}/${path}`;
    wireframe.setAppRoute(url, window.history.state.appPath, window.history.state.appId);
};
